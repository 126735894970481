import { CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export function AuthGuard(): CanActivateFn {
  return () => {
    const oauthService: AuthService = inject(AuthService);
      
    if (oauthService.isAuthenticated() ) {
      return true;
    }
  
    oauthService.redirectToLogin();
    return false;
  }
};
