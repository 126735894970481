import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthResponse } from '../interfaces/auth-response';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, map, take, throwError } from 'rxjs';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private refreshTokenTimeout: any;
  private userSubject: BehaviorSubject<AuthResponse | null>;
  public user: Observable<AuthResponse | null>;
  path: string = environment.serverUrl + "/auth";

  constructor(public jwtHelper: JwtHelperService,
    private router: Router,
    private http: HttpClient,
    private tokenService: TokenService) { 
      this.userSubject = new BehaviorSubject<AuthResponse | null>(null);
      this.user = this.userSubject.asObservable();
    }

  login(body: {email: string, password: string}) {
    return this.http.post<AuthResponse>(this.path + "/login", body)
      .pipe(map((user) => {
        this.tokenService.setAccessToken(user.accessToken);
        this.tokenService.setRefreshToken(user.refreshToken);
        
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        
        return user;
      }));
  }

  register(body: {name: string, email: string, password: string, company: string}) {
    return this.http.post<AuthResponse>(this.path + "/register", body);
  }

  activate(body: {email: string, password: string, invitationToken: string}) {
    return this.http.post<AuthResponse>(this.path + "/activate-staff", body);
  }

  logout() {
    // this.http.post<any>(`${this.path}/logout`, {}, { withCredentials: true }).pipe(take(1)).subscribe();
    this.tokenService.clearTokens();
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.router.navigate(['/accounts/sign-in']);
  }

  forgotPassword(body: {email: string}) {
    return this.http.post<AuthResponse>(this.path + "/forgot-password", body);
  }

  resetPassword(body: {email: string, password: string, resetToken: string}) {
    return this.http.post<AuthResponse>(this.path + "/reset-password", body);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('accessToken');
    return !this.jwtHelper.isTokenExpired(token);
  }

  private isRefreshTokenValid(): boolean {
    const token = localStorage.getItem('refreshToken');
    return !this.jwtHelper.isTokenExpired(token);
  }

  public redirectToLogin() {
    this.router.navigate(["/accounts","login"]);
  }

  public redirectToConsole() {
    this.router.navigate(["/console"]);
  }

  refreshToken() {
    if(this.isRefreshTokenValid()) {
      const refreshToken = localStorage.getItem('refreshToken');
      return this.http.post<AuthResponse>(this.path + '/refresh', {refreshToken})
        .pipe(map((user) => {
          this.tokenService.setAccessToken(user.accessToken);

          this.userSubject.next(user);
          this.startRefreshTokenTimer();

          return user;
        }));
    } else {
      return throwError(() => new Error());
    }
  }

  public get userValue() {
      return this.userSubject.value;
  }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtBase64 = this.userValue!.accessToken!.split('.')[1];
    const jwtToken = JSON.parse(atob(jwtBase64));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);

    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}
