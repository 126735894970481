import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './helpers/auth.guard';

const routes: Routes = [
  {
    path: 'accounts',
    loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'console',
    loadChildren: () => import('./views/console/console.module').then(m => m.ConsoleModule),
    canActivate: [AuthGuard()]
  },
  {
    path: '**',
    redirectTo: '/accounts/sign-in',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
