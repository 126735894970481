import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private accessTokenKey = "accessToken";
  private refreshTokenKey = "refreshToken";

  constructor(public jwtHelper: JwtHelperService,) { }

  public getAccessToken() {
    return localStorage.getItem(this.accessTokenKey);
  }

  public setAccessToken(token: string) {
    localStorage.setItem(this.accessTokenKey, token);
  }

  public getRefreshToken() {
    return localStorage.getItem(this.refreshTokenKey);
  }

  public setRefreshToken(token: string) {
    localStorage.setItem(this.refreshTokenKey, token);
  }

  public clearTokens() {
    localStorage.clear();
  }

  public getUserRole() {
    const token = localStorage.getItem(this.accessTokenKey);
    if(token) {
      const claims = this.jwtHelper.decodeToken(token);
      return claims['role'];
    }
  }

  public getName() {
    const token = localStorage.getItem(this.accessTokenKey);
    if(token) {
      const claims = this.jwtHelper.decodeToken(token);
      return claims['name'];
    }
  }

  public getUserID() {
    const token = localStorage.getItem(this.accessTokenKey);
    if(token) {
      const claims = this.jwtHelper.decodeToken(token);
      return claims['sub'];
    }
  }

  public getCompanyID() {
    const token = localStorage.getItem(this.accessTokenKey);
    if(token) {
      const claims = this.jwtHelper.decodeToken(token);
      return claims['companyId'];
    }
  }

isRole(roles: string[]) {
  const userRole = this.getUserRole();
  const result = roles.filter((role) => role === userRole);

  return result.length > 0;
}
}
